import {VOD_RAILS} from '@/store/getterTypes';
import {CLIPS_WATCH} from '@/pages';
import {ASSETS_TYPE} from '@/const';
import Vue from 'vue';

export default {
  [VOD_RAILS]: (state) => {
    const prepareItemData = (item) => {
      const {
        year,
        month,
        day,
      } = Vue.prototype.getFormattedDate({
        ts: +(new Date(item.date)),
        formatsOptions: {
          year: {year: 'numeric'},
          month: {month: 'short'},
          day: {day: '2-digit'},
        },
      });

      return {
        url: {
          name: CLIPS_WATCH.name,
          params: {
            id: item.id,
          },
        },
        image: item.assets?.find((asset) => asset.type === ASSETS_TYPE.image)?.path,
        title: item.title,
        preview: item.assets?.find((asset) => asset.type === ASSETS_TYPE.preview)?.path,
        day: `${day} ${month}, ${year}`,
        ...item,
      };
    };

    return state.vodRails?.map((rail) => ({
      title: rail.title,
      items: rail.vod_media_items.map(prepareItemData),
      id: rail.id,
      scrollTime: 5000,
      minCardWidth: 280,
      maxCardWidth: 380,
    })) || [];
  },
};
