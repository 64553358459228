<template>
  <div class="hello mt-5">
    <div class="image_wrapper">
      <div>4</div>
      <div class="img"><img src="../assets/img/404.png" alt=""/></div>
      <div>4</div>
    </div>
    <div class="text">
      <p>{{ $t('Hmm') }}</p>
      <p>{{ $t('Lose page') }}</p>
    </div>
    <div class="d-flex justify-content-center mb-5">
      <router-link :to="$options.PAGES.HOME" class="home">
        {{ $t('Go to', {page: $t('Home page')}) }}
      </router-link>
    </div>
  </div>
</template>

<script>
import {HOME} from '@/pages';

export default {
  name: 'NotFound',
  PAGES: {HOME},
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/colors";
@import "src/assets/css/mixins";

.image_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    object-fit: cover;
  }

  div {
    font-size: 12rem;
    line-height: 1;
    letter-spacing: 0;
    text-shadow: .7rem .7rem .2rem $card-background;
  }

  .img {
    display: flex;
    width: 10rem;
    height: 10rem;
    overflow: hidden;
  }

  @include media-max(xs) {
    div {
      font-size: 10rem;
    }
    .img {
      display: flex;
      width: 8rem;
      height: 8rem;
    }
  }
}

.text {
  font-size: 2rem;
  font-weight: 600;
  color: var(--settings-body-text-color);
  line-height: 1;
  margin-top: 3rem;

  p:first-child {
    margin-right: 1rem;
    font-size: 1.5em;
  }
}

.home {
  margin-top: 2rem;
  color: var(--settings-body-text-color);
  position: relative;
  border-radius: .4rem;
  padding: .8rem 2rem;
  text-decoration: none;
  transition: all .2s linear;
  overflow: hidden;
  box-shadow: 1px 1px 4px rgba($error-rgb, 0), inset 0 0 0 2px $error;
  font-weight: 600;
  font-size: 1rem;

  &:after {
    position: absolute;
    content: "";
    z-index: -1;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background: $error;
    transition: width .15s linear;
  }

  &:hover {
    box-shadow: 2px 2px 4px rgba($error-rgb, .6), inset 0 0 0 2px $error;
    &:after {
      width: 100%;
    }
  }
}
</style>
