import request from './request';
import {VOD_CATEGORIES_API, VOD_ITEMS_API, VOD_RAILS_API, VOD_SEARCH_API} from '@/urls';
import {API_METHODS} from '@/const';

export default {

  get_vod_medias: function(params = {}, token) {
    return request({
      url: VOD_ITEMS_API,
      method: API_METHODS.GET,
      params,
      token,
    });
  },
  get_vod_media(id, token) {
    return request({
      url: `${VOD_ITEMS_API}/${id}`,
      method: API_METHODS.GET,
      token,
    });
  },
  get_media_categories: function(params = {}) {
    return request({
      url: VOD_CATEGORIES_API,
      method: API_METHODS.GET,
      params,
    });
  },
  vod_search: function({
    limit = 10,
    offset = 0,
    title,
    sort_field = 'date',
    ...rest
  } = {}, token) {
    const params = {
      limit,
      offset,
      title,
      sort_field,
      ...rest,
    };
    return request({
      url: VOD_SEARCH_API,
      method: API_METHODS.GET,
      params,
      token,
    });
  },
  vod_rails() {
    return request({
      url: VOD_RAILS_API,
      method: API_METHODS.GET,
    });
  },
};
