import api from '../../api/api';
import * as ACTIONS from '../actionTypes';
import {ejectAbortSignal} from '@/utils/helpers';
import {UPDATE_VOD_RAILS} from '@/store/mutationTypes';

export default {
  [ACTIONS.GET_VOD_ITEMS]: async (state, params) => {
    const {token, props} = ejectAbortSignal(params);
    return await api.vodMediaApi.get_vod_medias(props, token);
  },
  [ACTIONS.GET_VOD_ITEM]: async (state, params) => {
    const {id, abortSignal: token} = params;
    return await api.vodMediaApi.get_vod_media(id, token);
  },
  [ACTIONS.GET_VOD_CATEGORIES]: async (state, params) => {
    return await api.vodMediaApi.get_media_categories(params);
  },
  [ACTIONS.SEARCH_IN_VOD]: async (state, params) => {
    const {token, props} = ejectAbortSignal(params);
    return await api.vodMediaApi.vod_search(props, token);
  },
  [ACTIONS.GET_VOD_RAILS]: async ({commit}) => {
    const {data: rails} = await api.vodMediaApi.vod_rails();
    commit(UPDATE_VOD_RAILS, rails);
  },
};
